$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-off-black: #0B0A08;
$color-yellow: #C2C38A;
$color-white-65: #D8D3D3A6;
$color-black-overlay: #00000080;

$primary-font: "K2D" Arial, sans-serif;
