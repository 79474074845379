@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";


html {
	background: $color-off-black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	// @include desktop-sm-down {
	// 	font-size: 15px;
	// }

	// @include tablet-down {
	// 	font-size: 14px;
	// }

	// @include phone-down {
	// 	font-size: 13px;
	// }

	// @include phone-sm-down {
	// 	font-size: 12px;
	// }


	@media screen and (max-height: 800px) {
		font-size: 14px;
	}

	@media screen and (max-height: 700px) {
		font-size: 13px;
	}

	@media screen and (max-height: 600px) {
		font-size: 12px;
	}

	@media screen and (max-height: 500px) {
		font-size: 11px;
	}

	// @media screen and (max-height: 600px) {
	// 	font-size: 13px;
	// }
}

body {
	color: $color-white;
	width: 100%;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;

	main {
		flex: 1;
	}
}

a {
	color: inherit;

	@include hover-focus {
		color: $color-white;
	}
}

.btn {
	text-transform: uppercase;
	font-size: rems(24);
	padding: rems(10) rems(60);

	@media screen and (max-height: 500px) {
		padding: rems(7) rems(40);
	}

	&.yellow {
		background-color: $color-yellow;
		color: $color-off-black;
	}
}

h2 {
	text-align: center;
	text-transform: uppercase;
	font-size: rems(48);

	@media screen and (max-height: 500px) {
		font-size: rems(36);
	}
}

.portrait-overlay {
	display: none;
	z-index: 100000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: $color-black-overlay;

	@media (orientation: portrait) {
		display: flex;
	}

	div {
		padding: rems(40);
		background-color: $color-yellow;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		p {
			font-size: rems(28);
			text-transform: uppercase;
			font-family: $primary-font;
			line-height: 1.5em;
			font-weight: 700;
			text-align: center;
			color: $color-off-black;
			width: 100%;
			max-width: rems(400);
			margin-bottom: rems(24);
		}

		img {
			width: rems(40);
		}
	}
}

.tv {
	// temp
	// display: none;
	// opacity: .2;

	pointer-events: none;
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 100vh;
	width: 156vh;
	max-width: none;

	@media screen and (max-aspect-ratio: 4/3) {
		left: 0;
		transform: none;
	}
}

.container {
	width: 156vh;
	padding-left: 0;
	margin-top: 7vh;

	@media screen and (max-aspect-ratio: 4/3) {
		margin-left: 0;
	}

	.content {
		background-color: $color-off-black;
		min-height: 100vh;
		margin-left: 4%;
		width: 100%;
		max-width: 73%;
		padding: 7vh 7vh 13vh 7vh;

		@media screen and (max-aspect-ratio: 4/3) {
			max-width: 107vh;
			padding: 7vh 7vh 13vh 15vh;
		}
	}
}

header {
	nav {
		margin: rems(10) 0;

		ul#nav-menu {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: rems(20);
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin: 0;

			li.nav-item {
				a {
					text-transform: uppercase;

				}
			}
		}
	}
}

#main {

	section {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: 1px solid $color-white;
		padding: rems(40) 0;
	}

	section#channels {

		p {
			text-align: center;
			font-size: rems(20);
			margin-bottom: rems(40);
		}
    a.btn {
        display: flex; /* Add flex display */
        justify-content: flex-start; /* Align items to the left */
        align-items: center; /* Center items vertically */
        text-align: center; /* Center the text horizontally */
        width: 100%;
        max-width: rems(280);
        margin-bottom: rems(40);

        i {
            margin-right: rems(8); /* Add margin-right to the icon */
        }
    }
}


	section#watch {
		padding-top: 0;

		h2 {}

		.embed-container {
			width: 100%;
			margin-bottom: rems(20);

			iframe {}
		}

		p {
			text-align: center;
			font-size: rems(24);
			text-transform: uppercase;
			margin-bottom: rems(20);
		}

		.btn {}
	}

	section#listen {

		h2 {
			margin-top: 0;
		}

		img {
			max-width: rems(450);
			margin-bottom: rems(30);
		}

		p {
			text-align: center;
			font-size: rems(32);
			text-transform: uppercase;
			margin-bottom: rems(30);
		}

		.btn {}
	}

	section#shows {
		padding-top: rems(80);

		h2 {
			margin: 0;
			display: flex;
			align-items: center;
			gap: rems(5);

			@media screen and (max-height: 500px) {
				font-size: rems(26);
			}

			span {}

			img {
				display: inline-block;
				height: 1em;
			}

			&.line-2 {
				margin-bottom: rems(50);
			}
		}

		#mitchell-tenpenny-not-today-form {
			width: 100%;
			display: flex;
			flex-direction: column;


			input[type="hidden"] {}

			.form-group {
				display: flex;
				flex-direction: column;
				margin-bottom: rems(24);

				label {
					text-transform: uppercase;
					margin-bottom: rems(6);
				}

				input {
					border: 1px solid $color-white;
					background-color: $color-off-black;
					color: $color-white-65;
					text-transform: uppercase;
					font-size: rems(20);
					outline: none;
					width: 100%;
				}

				textarea {
					border: 1px solid $color-white;
					padding: rems(24);
					background-color: $color-off-black;
					min-height: rems(420);
					color: $color-white-65;
					text-transform: uppercase;
					font-size: rems(20);
					outline: none;

					&::placeholder {
						color: $color-white-65;
						text-transform: uppercase;
						font-size: rems(20);
					}
				}
			}

			.form-name-group {
				display: flex;
				gap: rems(20);
				width: 100%;

				.form-group {
					flex: 1;
				}
			}


			.input-group {
				.checkbox {
					margin-bottom: rems(20);

					label {
						display: flex;
						align-items: center;
						margin-left: rems(6);

						input[type="checkbox"] {
							margin-right: rems(10);
							background-color: $color-off-black;

							&::before {
								content: '';
								display: inline-block;
								width: 14px;
								height: 14px;
								background-color: $color-off-black;
								border: 1px solid $color-white;
								border-radius: 0;
								vertical-align: middle;
								transform: translate(-25%, -25%);
								padding: rems(2);
							}

							&:checked::before {
								content: '\f00c';
								font: var(--fa-font-solid);
								color: $color-white;
							}
						}

						p {
							margin-bottom: 0;
						}
					}
				}
			}

			button[type="submit"] {}
		}
	}
}


footer {
	div {
		margin-top: rems(40);
		text-align: center;
		font-size: rems(11);
		text-transform: uppercase;
	}
}